/* ********************************************************************
 * Some variables
 * ********************************************************************/

@import 'colors';

/* ********************************************************************
 * Mandatory font face
 * ********************************************************************/

@font-face {
  font-family: 'SansitaOneRegular';
  src: url('/fonts/sansitaone-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

/* ********************************************************************
 * Box sizing
 * ********************************************************************/

// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* ********************************************************************
 * Body
 * ********************************************************************/

html,
body {
  font-family: SansitaOneRegular;

  /* http://maximilianhoffmann.com/posts/better-font-rendering-on-osx */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $backgroundColor;
  color: $textColor;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* ********************************************************************
 * Modules
 * ********************************************************************/

@import 'balls';
@import 'screen';
@import 'screen-home';
@import 'screen-play';
@import 'screen-pause';
@import 'screen-gameover';
@import 'screen-scores';
@import 'screen-about';

/* ********************************************************************
 * Description for browsers lacking some support for SVG, flexbox, etc.
 * ********************************************************************/

@keyframes revealDescription {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: calc(1em + 3vw);
  overflow-y: scroll;

  line-height: 1.3;

  // animate opacity
  opacity: 0;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-name: revealDescription;

  h1 {
    font-size: 3em;
    text-align: center;
    margin: 1rem 0;
  }

  h2,
  p,
  li {
    font-family: sans-serif;
  }

  p.logo {
    float: right;
    width: 25%;
    margin: 0 0 1em 1em;
  }

  a:link,
  a:visited {
    color: #ccc;
  }

  a:hover,
  a:active {
    color: #fdd;
  }

  .error {
    padding: 0.2em 0.5em;
    border: 1px solid darken($badColor, 10%);
    background-color: lighten($badColor, 40%);
    color: $badColor;

    a:link,
    a:visited {
      color: $badColor;
    }

    a:hover,
    a:active {
      color: darken($badColor, 10%);
    }
  }
}

/* ********************************************************************
 * A few extra things
 * ********************************************************************/

/* no text selection anywhere: https://developer.mozilla.org/fr/docs/Web/CSS/user-select */
* {
  user-select: none;
}

/* no delay on focusable elements in (future) iOS: https://adactio.com/journal/10019 */
a,
button,
input,
select,
textarea,
label,
summary {
  touch-action: manipulation;
}
