// Colors palette
$colorOrangeLight: #f39c12;
$colorOrangeDark: #e67e22;
$colorGreenLight: #2ecc71;
$colorGreenDark: #27ae60;
$colorBlueLight: #3498db;
$colorBlueDark: #2980b9;
$colorPurpleLight: #9b59b6;
$colorPurpleDark: #8e44ad;
$colorBrownLight: #aa8866;
$colorBrownDark: #776655;
$colorRedLight: #e74c3c;
$colorRedDark: #c0392b;

// Global colors
$backgroundColor: #444;
$textColor: #fff;
$buttonColor: #ccc;

$goodColor: $colorGreenDark;
$badColor: $colorRedDark;
