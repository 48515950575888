/* ********************************************************************
 * Game over screen
 * ********************************************************************/

#gameover {
  p {
    text-align: center;
  }

  .message {
    font-size: 5vmin;
    margin: 2em 1em 0;
  }

  .score {
    font-size: 10vmin;
    margin: 0 1em;
  }
}
