.ball {
  text {
    font-size: 16px;
    text-anchor: middle;
    fill: #fff;
    stroke-width: 0;
  }
}

.ball1 {
  fill: $colorOrangeLight;
  stroke: $colorOrangeDark;
}

.ball2 {
  fill: $colorGreenLight;
  stroke: $colorGreenDark;
}

.ball3 {
  fill: $colorBlueLight;
  stroke: $colorBlueDark;
}

.ball4 {
  fill: $colorPurpleLight;
  stroke: $colorPurpleDark;
}

.ball5 {
  fill: $colorBrownLight;
  stroke: $colorBrownDark;
}

.ball6 {
  fill: $colorRedLight;
  stroke: $colorRedDark;
}
