/* ********************************************************************
 * Pause screen
 * ********************************************************************/

#pause {
  .sound {
    display: none;

    .soundeffectsloaded &, .ambiancesoundloaded & {
      display: block;
    }

    .on { display: none; }
    .off { display: block; }

    &.on {
      .on { display: block; }
      .off { display: none; }
    }
  }
}
