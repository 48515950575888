/* ********************************************************************
 * About screen
 * ********************************************************************/

#about {
	.about {
		margin: 5vmin;
		display: flex;
		flex-direction: column;

		@media (min-aspect-ratio: 20/24) {
			flex-direction: row;
		}
	}

	.description {
		a:link, a:visited {
			color: $colorBlueLight;
			text-decoration: underline;
		}

		@media (min-aspect-ratio: 20/24) {
			flex: 3;
		}
	}

	.social {
		text-align: center;
		display: flex;
		justify-content: center;

		a {
			margin: 1em;
		}

		a, a svg {
			width: 15vmin;
			height: 15vmin;
		}

		@media (min-aspect-ratio: 20/24) {
			flex-direction: column;
		}
	}
}
